import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import {  Environment, Center } from '@react-three/drei'
import { easing } from 'maath'
import * as THREE from 'three';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import Model from './Model'
import { getTexture } from '../lib/textureCreation'
import rotateButton from '../assets/images/rotate_button.svg'
import resetRotationButton from '../assets/images/reset_rotation.svg'
import { appColors, modelCoordinates } from '../utils/studioSettings';
import "./Stage.css"

export const Stage = ({ position = [0, 0, 2.5], fov = 25, design, selections, selectedDesign, 
                        selectedInputOptions, studio=false, canvasRef, saveImage, setSaveImage,
                        setExecuteSave}) => {

    const gender = selections?.gender?.toLowerCase()
    const type = selections?.item?.toLowerCase()?.replace(/ /g, '_')
    const garment = selections?.garment?.toLowerCase()
    const keeper = selections?.shirt?.toLowerCase().includes('goalkeeper')
    const inputs = selectedInputOptions

    const [rotation, setRotation] = useState(0)
    const [intervalId, setIntervalId] = useState(null)
    const [keyboardIntervalId, setKeyboardIntervalId] = useState(null); // For keyboard rotation

    const [texture, setTexture] = useState(null)

    const handleRotateLeft = useCallback(() => {
        setRotation((prev) => prev - ((2*Math.PI)/13));
    }, [setRotation])

    const handleRotateRight = useCallback(() => {
        setRotation((prev) => prev + ((2*Math.PI)/13)); 
    }, [setRotation])

    const handleRotateLeftKey = useCallback(() => {
        setRotation((prev) => prev - ((2*Math.PI)/13));
    }, [setRotation])

    const handleRotateRightKey = useCallback(() => {
        setRotation((prev) => prev + ((2*Math.PI)/13)); 
    }, [setRotation])


    const handleResetRotation = useCallback(() => {
        setRotation(0)
    }, [setRotation])


    const startRotateLeft = useCallback(() => {
        if (intervalId) return; 

        const id = setInterval(() => {
            setRotation((prev) => prev - ((2*Math.PI)/13));
        }, 150); 

        setIntervalId(id);
    }, [intervalId, setRotation]);

    const startRotateRight = useCallback(() => {
        if (intervalId) return;

        const id = setInterval(() => {
            setRotation((prev) => prev + ((2*Math.PI)/13)); 
        }, 150); 
        
        setIntervalId(id);
    }, [intervalId, setRotation]);


    const stopRotate = useCallback(() => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    }, [intervalId, setIntervalId])

    const stopRotateKey = useCallback(() => {
        if (keyboardIntervalId) {
            clearInterval(keyboardIntervalId);
            setKeyboardIntervalId(null);
        }
    }, [keyboardIntervalId, setKeyboardIntervalId]);


    const handleKeyPress = useCallback((event) => {
        if (event.key === 'ArrowLeft') {
            handleRotateLeftKey()

        } else if (event.key === 'ArrowRight') {
            handleRotateRightKey()
        }
        else if(event.code === 'Space'){
            handleResetRotation()
        }
    }, [handleRotateLeftKey, handleRotateRightKey, handleResetRotation]);

    const handleKeyRelease = useCallback((event) => {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            stopRotateKey(); 
        }
    }, [stopRotateKey]);

    useEffect(()=>{

        async function setDesign() {

            const base64Texture = await getTexture(gender, type, garment, keeper, inputs, selectedDesign, design)

            if(base64Texture){
                const image = new Image();
                image.src = base64Texture;
                const newTexture = new THREE.Texture();
                newTexture.image = image;
                image.onload = function () {
                    newTexture.needsUpdate = true;
                };
                newTexture.flipY = false
                newTexture.encoding = THREE.sRGBEncoding;
                setTexture(newTexture)
            }
        }


        if(saveImage){
            setRotation(0)
        }
        else{
            setDesign()
        }

        window.addEventListener('keydown', handleKeyPress)
        window.addEventListener('keyup', handleKeyRelease);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener('keyup', handleKeyRelease);
        };


    }, [gender, type, garment, keeper, inputs, selectedDesign, design, setRotation, saveImage, handleKeyPress, handleKeyRelease])

   

    return (
            <div className='stage'>
                
                <Canvas key={garment} ref={canvasRef} shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root')} eventPrefix="client">
                
                    <directionalLight position={[-1, 1, 0.866]}  color={appColors.LIGHTING_COLOR} intensity={4.5}/>
                    <directionalLight position={[1, 1, 0.866]}  color={appColors.LIGHTING_COLOR} intensity={5.5}/>
                    {garment==="shirts" && <directionalLight position={[0, 1, 0.5]}  color={appColors.LIGHTING_COLOR} intensity={15.5}/>}
                    {garment==="shorts" && <directionalLight position={[0, 1, 0.5]}  color={appColors.LIGHTING_COLOR} intensity={5.5}/>}

                    <Environment
                            preset={null} 
                            files={null}  
                            background
                            scene={new THREE.Scene()}  
                            children={(scene) => {
                            const pmremGenerator = new THREE.PMREMGenerator(scene);
                            const envTexture = pmremGenerator.fromScene(new RoomEnvironment()).texture;
                            scene.environment = envTexture;
                            scene.background = envTexture;
                            }}
                        />

                    <CameraRig deltaRotation={rotation} keeper={keeper} garment={garment} 
                                saveImage={saveImage} setSaveImage={setSaveImage} setExecuteSave={setExecuteSave}>
                        <Center>
                            <Model gender={gender} type={type} garment={garment} keeper={keeper} newTexture={texture}/>
                        </Center>
                    </CameraRig>
                </Canvas>
                
                {studio && <div>
                    <img className='rotate-button-left' src={rotateButton} alt="rotate left" onClick={handleRotateLeft}
                    onMouseDown={startRotateLeft} onMouseUp={stopRotate} onMouseLeave={stopRotate} 
                    onTouchStart={startRotateLeft} onTouchEnd={stopRotate} onTouchCancel={stopRotate}
                    />

                    <img className='rotate-button-right' src={rotateButton} alt="rotate right" onClick={handleRotateRight}
                    onMouseDown={startRotateRight} onMouseUp={stopRotate} onMouseLeave={stopRotate} 
                    onTouchStart={startRotateRight} onTouchEnd={stopRotate} onTouchCancel={stopRotate}/> 

                    <img className='reset-rotation-button' src={resetRotationButton} alt="reset" onClick={handleResetRotation}/> 
                </div>}

            </div>  
    )
}


function CameraRig({deltaRotation, children, keeper, garment, saveImage, setSaveImage, setExecuteSave }) {
    const group = useRef()

    useFrame((state, delta) => {

        let cameraZ = modelCoordinates.MODEL_Z
        let cameraY = modelCoordinates.MODEL_Y
        const screenWidth = window.innerWidth
        if(screenWidth<=800){
            cameraY = modelCoordinates.MODEL_Y_MOBILE
            cameraZ = modelCoordinates.MODEL_Z_MOBILE
        }

        easing.damp3(state.camera.position, [0, cameraY, cameraZ], 0.25, delta)    
        easing.dampE(group.current.rotation, [0.2, deltaRotation, group.current.rotation.z], 0.25, delta)

        if(saveImage && group.current.rotation.y===0){
            setSaveImage(false)
            setExecuteSave(true)
        }
    })

    return <group ref={group}>{children}</group>
}



