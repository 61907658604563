import React, {useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import backButton from '../../assets/images/back_button.svg'
import { STUDIO_STACK, AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './RegisterWithEmail.css';
import {Input} from 'reactstrap';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { loginWithEmailPhone } from '../../lib/authActions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { appColors } from '../../utils/studioSettings';

function LoginWithMobile() {

  const navigate = useNavigate()
  const [loginFailed, setLoginFailed] = useState(false)
  const [telephone, setTelephone] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const [warningMsg, setWarningMsg] = useState("")

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const goBack = () => {
    navigate(-1)
  }

  const loginWithMobile = async () => {
    function onSuccess (userData) {
      const {access, refresh} = userData.tokens
      window.localStorage.setItem("username", userData?.user?.profile?.fullName)
      window.localStorage.setItem("access", JSON.stringify(access))
      window.localStorage.setItem("refresh", JSON.stringify(refresh))
      
      navigate(STUDIO_STACK.SELECTION_PAGE, {state: {fromAuth: true}})
    }

    function onFail (errMsg) {
      if(errMsg){
        setWarningMsg(errMsg)
      }
      else{
        setLoginFailed(true)
      }
    }

    if(!password){
        setWarningMsg("Please enter password")
    }
    else{
        const mobile = `+${telephone}`
        await loginWithEmailPhone(null, mobile, password, onSuccess, onFail)
    }

  }

  const goToRegister = () => {
    navigate(AUTHENTICATION_STACK.REGISTER_OPTIONS)
  }

  const goToForgotPassword = () => {
    navigate(AUTHENTICATION_STACK.FORGOT_PASSWORD_WITH_MOBILE)
  }

  return (
    <div className="app-container" >
      

      <div className='auth-top-bar'>
        <img className='back-button' src={backButton} alt="hello" onClick={goBack}/> 
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>

      <div className='welcome-text-box'>
        <p className="welcome-text">Welcome back!</p>
        <p className="welcome-msg"> Log in to continue enjoying Clubbie.</p>
      </div>


      {loginFailed && <div>
                            <p className="login-failed-msg">Login failed.</p>
                        </div>}
       
        {warningMsg && <p className='info-warning'>{warningMsg}</p>}


      <div className='button-container-email-register'>

        <PhoneInput
            country={'us'}
            value={telephone}
            onChange={(telephone) => setTelephone(telephone)}
            inputStyle={mobileInputStyles.inputStyle}
            buttonStyle={mobileInputStyles.buttonStyle}
            containerStyle={mobileInputStyles.containerStyle}
            dropdownStyle={mobileInputStyles.dropDownStyle}
            containerClass='mb-4'
            />

        <div className='password-input'>
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="mb-5 email-input"
          />
          <Button 
            type="button" 
            onClick={togglePasswordVisibility} 
            className="password-toggle-button"
          >
            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </Button>
        </div>

        <button className='mb-4 go-register-button' onClick={goToForgotPassword}>
            Forgot password?
        </button>

        <Button 
            className='register-button'
            onClick={loginWithMobile}>
            Log in   
        </Button>

        <p className='go-register-msg'>
            You don't have an account?
          <button className='go-register-button' onClick={goToRegister}>
            Register.
          </button>
        </p>

      </div>
  </div>
  )
}

const mobileInputStyles = {
    inputStyle: {
        width: "100%",
        backgroundColor: "transparent",
        borderRadius: "25px",
        borderColor: appColors.CLUBBIE_WHITE,
        height: "50px",
        boxShadow: 'none', 
        color: appColors.CLUBBIE_WHITE,
        fontSize: "15px"
    },

    buttonStyle: {
        backgroundColor: "transparent",
        borderBottom: 'none', 
        borderLeft: 'none',
        borderTop: 'none'
    },

    dropDownStyle: {
        backgroundColor: appColors.APP_BACKGROUND, 
        color: appColors.CLUBBIE_WHITE
    },

    containerStyle: {
        border: 'none', 
        boxShadow: 'none',
    }
}

export default LoginWithMobile