import React, {useState, useEffect} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import backButton from '../../assets/images/back_button.svg'
import { STUDIO_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import './Verification.css';
import { verifyAccount, resendVerificationCode, activateTemporaryAcc } from '../../lib/authActions';
import OverlayLoader from '../../components/OverlayLoader';


function Verification() {

  const navigate = useNavigate()
  const [verificationFailed, setVerificationFailed] = useState(false)
  const [resendLoading, setResendloading] = useState(false)

  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);

  const location = useLocation()
  const {user, registrationInfo} = location?.state

  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''));

  const handleInputChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value)) {  
      let newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (element, index, event) => {
    if (event.key === "Backspace") {
      if (!verificationCode[index] && element.previousSibling) {
        element.previousSibling.focus();
      }
      else if(verificationCode[index]){
        let newCode = [...verificationCode];
        newCode[index] = '';
        setVerificationCode(newCode);
      }
    }
  };

  const verify = async ()=> {
    function onSuccess(verifiedUser) {
      const {access, refresh} = verifiedUser.tokens
      window.localStorage.setItem("username", verifiedUser?.user?.profile?.fullName)
      window.localStorage.setItem("access", JSON.stringify(access))
      window.localStorage.setItem("refresh", JSON.stringify(refresh))
      
      navigate(STUDIO_STACK.SELECTION_PAGE, {state: {fromAuth: true}})
    }

    function onFail(err) {
        setVerificationFailed(true)
    }

    const confirmationCode = verificationCode.join("")
    if(confirmationCode.length===6){
        if (user){
            await verifyAccount(confirmationCode, user, onSuccess, onFail)
        }
        else{
            await activateTemporaryAcc(confirmationCode, registrationInfo, onSuccess, onFail)
        }
    }

  }

  const resendCode = async () => {
    function onSuccess(){
        setResendloading(false)
    }

    function onFail(){
        setResendloading(false)
    }

    setIsResendDisabled(true);
    setTimer(15);
    setResendloading(true)

    await resendVerificationCode(registrationInfo, user, onSuccess, onFail)
  }

  const goBack = () => {
    navigate(-1)
  }

  useEffect(() => {

    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [timer]);


  return (
    <div className="app-container" >

      <div className='auth-top-bar'>
        <img className='back-button' src={backButton} alt="hello" onClick={goBack}/> 
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>

      <div className='welcome-text-box-email-register'>
        <p className="welcome-text">Verify your account</p>
        <p className="welcome-msg">{`We've sent you a verification code to your ${registrationInfo?.email ? "email" : "phone number"}.`}</p>
      </div>

      {verificationFailed && <div>
                            <p className="login-failed-msg">Verification failed.</p>
                        </div>}

      <div className='button-container-email-register'>

        <div className='verification-code'>
            {verificationCode.map((value, index) => (
                <input
                className='code-slot'
                key={index}
                type="text"
                maxLength="1"
                value={value}
                onChange={(e) => handleInputChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e.target, index, e)}
                />
            ))}
        </div>

        <p className='go-login-msg'>
            Didn't receive the code?
          <button className='resend-button' onClick={resendCode} disabled={isResendDisabled}>
            Resend
          </button>
            {isResendDisabled && <p>Resend code again in {timer} seconds</p>}
        </p>

        <Button 
            className='register-button'
            onClick={verify}>
            Register   
        </Button>

        {resendLoading && <OverlayLoader/>}    

      </div>
  </div>
  )
}


export default Verification