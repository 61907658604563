import React, {useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import './RegisterOptions.css'
import { STUDIO_STACK, AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { GoogleOutlined, AppleFilled, MobileFilled, MailFilled } from '@ant-design/icons';
import { signInWithGooglePopup, signInWithApplePopUp } from "../../utils/firebase.utils"
import { googleLoginRequest, appleLoginRequest } from '../../lib/authActions';
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { Button } from 'reactstrap';

function RegisterOptions() {

  const navigate = useNavigate()
  const [isLoginFailed, setLoginFailed] = useState(false)

  const goToLogin = () => {
    navigate(AUTHENTICATION_STACK.LOGIN_OPTIONS)
  }

  const handleGoogleSignIn = async () => {

        function onSuccess(userData) {
          const {access, refresh} = userData.tokens
          window.localStorage.setItem("username", "")
          window.localStorage.setItem("access", JSON.stringify(access))
          window.localStorage.setItem("refresh", JSON.stringify(refresh))

          navigate(STUDIO_STACK.SELECTION_PAGE, {state: {fromAuth: true}})
        }

        function onFail(){
            setLoginFailed(true)
        }

        const response = await signInWithGooglePopup();
        const credential =  GoogleAuthProvider.credentialFromResult(response)
        const idToken = credential.idToken
        await googleLoginRequest(idToken, onSuccess, onFail)
    }

  const handleAppleSignIn = async () => {
    function onSuccess(userData) {
      const {access, refresh} = userData.tokens
      window.localStorage.setItem("username", "")
      window.localStorage.setItem("access", JSON.stringify(access))
      window.localStorage.setItem("refresh", JSON.stringify(refresh))
      
      navigate(STUDIO_STACK.SELECTION_PAGE, {state: {fromAuth: true}})
    }

    function onFail(){
        setLoginFailed(true)
    }

    const response = await signInWithApplePopUp();
    const credentials = OAuthProvider.credentialFromResult(response)

    await appleLoginRequest(credentials, onSuccess, onFail)
  }

  const goToRegisterWithEmail = () => {
    navigate(AUTHENTICATION_STACK.REGISTER_WITH_EMAIL)
  }

  const goToRegisterWithMobile = () => {
    navigate(AUTHENTICATION_STACK.REGISTER_WITH_MOBILE)
  }

  const goToPrivacyPolicy = () => {
    navigate(AUTHENTICATION_STACK.PRIVACY_POLICY)
  }

  const goToTermsAndConditions = () => {
    navigate(AUTHENTICATION_STACK.TERMS_AND_CONDITIONS)
  }


  return (
    <div className="app-container-options" >

      <div className='my-4 auth-top-bar'>
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>
      
      <div className='welcome-text-box'>
        <p className="welcome-text">Register with Clubbie</p>
        <p className="welcome-msg">Create your account.</p>
      </div>

      {isLoginFailed && <div>
                            <p className="login-failed-msg">Registration failed.</p>
                        </div>}

      <div className='button-container'>
        <Button className='login-option-button' onClick={handleGoogleSignIn}>
          <GoogleOutlined/>
          <span className='login-option-text'> Continue with Google </span>
        </Button>

        <Button className='login-option-button' onClick={handleAppleSignIn}>
            <AppleFilled/>
            <span className='login-option-text'> Continue with Apple </span>        
        </Button>

        <Button className='login-option-button' onClick={goToRegisterWithEmail}>
          <MailFilled/>
          <span className='login-option-text'> Continue with Email </span>    
        </Button>

        <Button className='login-option-button' onClick={goToRegisterWithMobile}>
            <MobileFilled/>
            <span className='login-option-text'> Continue with Mobile Number </span>   
        </Button>

        <p className='go-login-msg'>
            Already have an account?
          <button className='go-login-button' onClick={goToLogin}>
            Log in.
          </button>
        </p>

        <p className='terms-msg'>
            By creating an account you accept our<br></br>
          <button className='terms-button' onClick={goToTermsAndConditions}>
            Terms and conditions.
          </button>
          Read our 
          <button className='terms-button' onClick={goToPrivacyPolicy}>
            Privacy Policy.
          </button>
        </p>

      </div>
  </div>
  )
}


export default RegisterOptions