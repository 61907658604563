import React, { useCallback, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { decreaseCartItemQunatity, getCart, increaseCartItemQunatity, removeAtIndex } from '../redux/cartSlice';
import { FaCirclePlus, FaTrash } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";
import SizePopUp from '../components/SizePopUp';
import PopUpOption from '../components/PopUpOption';
import './Cart.css'
import { placeOrder } from '../lib/actions';
import OverlayLoader from '../components/OverlayLoader';
import { prices } from '../utils/prices';
import OrderPopUp from '../components/OrderPopUp';
import PopUpWithMsg from '../components/PopUpWithMsg';
import { fixedTexts } from '../utils/fixedTexts';
import SizeChartPopUp from '../components/SizeChartPopUp';
import { isValidEmail } from '../utils/helpers';
import backButton from '../assets/images/back_button.svg'
import LoadingImage from '../components/LoadingImage';

const getItemPrice = (item) => {
    const garment = item.item?.selections?.garment.toLowerCase()
    const sizes = item?.sizes

    let cost = 0
    for(let i = 0; i<sizes.length; i++){
        const type = sizes[i].type === "Kids" ? 'child' : 'adult'
        const price = prices[garment][type]
        cost = cost + price
    }

    return cost
}

function Cart() {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cartItems = useSelector(getCart)

    const showPriceInfo = false

    const [showOrderPopUp, setShowOrderPopUp] = useState(false)
    const [orderConfirmed, setOrderConfirmed] = useState(false)
    const [orderFailed, setOrderFailed] = useState(false)
    const [showChart, setShowChart] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null)

    const [contactEmail, setContactEmail] = useState("")
    const [emailWarning, setEmailWarning] = useState(false)
    const [orderInstructions, setOrderInstructions] = useState("")
    const [teamName, setTeamName] = useState("")

    const screenWidth = window.innerWidth;
    const isComputer = screenWidth > 768 


    const increase = (index) => {
        dispatch(increaseCartItemQunatity({index}))
    }

    const decrease = (index) => {
        dispatch(decreaseCartItemQunatity({index}))
    }

    const deleteButtonPress = (index) => {
        setShowDeletePopUp(true)
        setDeleteIndex(index)
    }

    const removeFromCart = (index) => {
        dispatch(removeAtIndex({index}))
        setDeleteIndex(null)
        setShowDeletePopUp(false)
    }

    const closeDeletePopUp = () => {
        setShowDeletePopUp(false)
        setDeleteIndex(null)
    }

    const prepareOrderData = useCallback(async () => {
        function onSuccess () {
            setIsLoading(false)
            setOrderConfirmed(true)
        }

        function onFail () {
            setIsLoading(false)
            setOrderFailed(true)
        }

        if(!contactEmail || !isValidEmail(contactEmail)){
            setEmailWarning(true)
        }

        else{
            setEmailWarning(false)
            setShowOrderPopUp(false)
            setIsLoading(true)

            const userInfo =  {
                email: contactEmail, 
                instructions: orderInstructions,
                teamName: teamName
            }

            await placeOrder(cartItems, userInfo, onSuccess, onFail)
        }
    }, [cartItems, contactEmail, orderInstructions, teamName, setEmailWarning, setShowOrderPopUp, setIsLoading])


    const getTotalCost = useCallback(() => {
        let cost = 0
        for(let i =0; i<cartItems.length; i++){
            const item = cartItems[i]
            const itemCost = getItemPrice(item)
            cost = cost + itemCost
        }

        return cost
    }, [cartItems])

    return (
        <div fluid className='cart-page'>
            { isComputer ? 
            <div>
                <Row>
                    <Col>
                        <div>
                            {
                                cartItems && cartItems.length ? 
                                <div>

                                    <div className='p-5 filled-cart-msg'>
                                        <p>your Cart</p>
                                        <PopUpOption text="view size chart" minWidth='150px' click={() => setShowChart(true)}/>
                                    </div>

                                    {cartItems.map((item, index) => {
                                        return (
                                            <Row key={index}>
                                                <Col md={3} className='p-4'>
                                                {
                                                    <LoadingImage src={item?.item?.designImage} alt="design" styleClass={'saved-design-image'}/>
                                                }
                                                
                                                </Col>
                                                <Col className='py-5'>
                                                    <p className='item-title'>
                                                        {item.item?.selections?.garment}
                                                    </p>

                                                    <p className='item-quantity-heading'>Quantity: 
                                                        <FaCircleMinus onClick={()=>decrease(index)} className='decrease-quantity'/> 
                                                        <span className='item-quantity'>{item.quantity}</span> 
                                                        <FaCirclePlus onClick={()=>increase(index)} className='increase-quantity'/>
                                                    </p>

                                                    {item.item?.selections?.garment!=="socks" && <SizePopUp item={item} index={index}/>}

                                                    <FaTrash onClick={()=>deleteButtonPress(index)} className={`delete-item ${item.item?.selections?.garment==="socks" && "socks"}`}/>

                                                    {showPriceInfo && <p className='item-total'>
                                                        {`Item total: £ ${getItemPrice(item)}`} 
                                                    </p>}
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                                :
                                <div className='p-5 empty-cart-msg'>
                                    <h3>Your cart is empty.</h3>
                                    <p>Start designing your teamwear to add items here!</p>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='cost-order'>
                            {showPriceInfo && cartItems.length > 0 && 
                            <> 
                                <p>
                                <span className='cart-total'>{`Total cost: `}</span>
                                <span className='cart-total-figure'>{`£ ${getTotalCost()} + VAT`}</span>
                                </p>
                            </>}

                            <div className="px-5 cart-bottom-bar" >
                                {cartItems.length > 0 && <p className='mt-4 design-team-note'>{fixedTexts.orderMsgs.noteFromDesignTeam}</p>}
                                
                                <div className='back-order-buttons'>
                                    <PopUpOption text="Back" minWidth='150px' click={() => navigate(-1)}/>
                                    {cartItems.length > 0 && <PopUpOption text="Get Quote" minWidth='250px' click={()=> setShowOrderPopUp(true)} disabled={!cartItems.length}/>}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div> 
            : 
            <div>
                { cartItems && cartItems.length ? 
                    <div>
                        <div className='p-5 filled-cart-msg-mobile'>
                            <img className='back-button-cart' src={backButton} alt="hello" onClick={() => navigate(-1)}/> 
                            <p>your Cart</p>
                        </div>

                        <div className='py-3 size-chart-button-mobile'>
                            <PopUpOption text="view size chart" minWidth='250px' click={() => setShowChart(true)}/>
                        </div>

                        {cartItems.map((item, index) => {
                            return (
                                <Row key={index} className='cart-item-mobile'>

                                    <LoadingImage src={item?.item?.designImage} alt="design" styleClass={'cart-design-image-mobile'}/>

                                    <Col className='cart-item-buttons'>
                                        <p className='item-title'>
                                            {item.item?.selections?.garment}
                                        </p>

                                        <p className='item-quantity-heading'>Quantity: 
                                            <FaCircleMinus onClick={()=>decrease(index)} className='decrease-quantity'/> 
                                            <span className='item-quantity'>{item.quantity}</span> 
                                            <FaCirclePlus onClick={()=>increase(index)} className='increase-quantity'/>
                                        </p>

                                        <div>
                                            <SizePopUp item={item} index={index}/>
                                            <FaTrash onClick={()=>deleteButtonPress(index)} className='delete-item'/>                         
                                        </div>
                                        

                                        {showPriceInfo && <p className='item-total'>
                                            {`Item total: £ ${getItemPrice(item)}`} 
                                        </p>}
                                    </Col>
                                </Row>
                            )
                        })}
                        
                        <div className='py-3 place-order-button-mobile'>
                            {showPriceInfo && <p>
                                <span className='cart-total'>{`Total cost: `}</span>
                                <span className='cart-total-figure'>{`£ ${getTotalCost()} + VAT`}</span>
                            </p>}

                            <p className="mt-4 design-team-note mobile">{fixedTexts.orderMsgs.noteFromDesignTeam}</p>
                            <PopUpOption text="Get Quote" minWidth='250px' click={()=> setShowOrderPopUp(true)} disabled={!cartItems.length}/>
                        </div>

                    </div>
                    
                    
                    : 
                    <div className='p-5 empty-cart-msg'>
                         <h3>Your cart is empty.</h3>
                         <p>Start designing your teamwear to add items here!</p>
                        <PopUpOption text="Back" minWidth='150px' click={() => navigate(-1)}/>
                    </div>
                }


            </div> 
            
            }

            {isLoading && <OverlayLoader />}

            <OrderPopUp showPopUp={showOrderPopUp}
                        email={contactEmail} 
                        setEmail={setContactEmail}
                        instructions={orderInstructions}
                        setInstructions={setOrderInstructions}
                        teamName={teamName}
                        setTeamName={setTeamName}
                        popUpMsg={'Please provide the following details'} 
                        closePopUp={()=> setShowOrderPopUp(false)}
                        emailWarning={emailWarning}
                        confirmOrder={prepareOrderData}/>

            {(orderConfirmed || orderFailed) && <PopUpWithMsg 
                                                popUpMsg={orderConfirmed ? fixedTexts.orderMsgs.orderConfirmedMsg : fixedTexts.orderMsgs.orderFailedMsg}
                                                justMsg={true} 
                                                failMsg={orderFailed}
                                                closePopUp={
                                                    ()=>{setOrderConfirmed(false)
                                                        setOrderFailed(false)
                                                    }}/>}

            <SizeChartPopUp showChart={showChart} closePopUp={()=>setShowChart(false)}/>

            {showDeletePopUp && <PopUpWithMsg 
                                    onOptionPress={() => removeFromCart(deleteIndex)} 
                                    optionText={'Remove'} 
                                    popUpMsg={'Are you sure you want to remove this item from your cart?'} 
                                    closePopUp={closeDeletePopUp}
                                    isDelete={true} />}
        </div>
        
        
    )
}

export default Cart;
