import React, {useCallback, useEffect, useState} from 'react'
import ClubbieLoader from './ClubbieLoader'
import './LoadingImage.css'

function LoadingImage({src, alt, styleClass, onClick}) {

    const [isLoading, setIsLoading] = useState(true)
    const [imageSrc, setImageSrc] = useState("")

    const handleImageLoad = useCallback(() => {
        setIsLoading(false)
    }, [])

    useEffect(() => {
        setIsLoading(true)
        setImageSrc(src)
    }, [src])

    return (
        <div className='loading-image-container'>
            {isLoading && 
                <div className='design-image-loader'>
                    <ClubbieLoader/>
                </div>
            }

            <img src={imageSrc} 
                alt={alt}
                className={styleClass}
                onLoad={handleImageLoad}
                onError={()=>setIsLoading(false)}
                style={{ opacity: isLoading ? 0 : 1, transition: 'opacity 0.5s ease-in' }} 
                onClick={onClick}
                /> 
        </div>
    )
}

export default LoadingImage