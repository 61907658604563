import React, {useCallback, useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import './LoginOptions.css'
import { STUDIO_STACK, AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { GoogleOutlined, AppleFilled, MobileFilled, MailFilled } from '@ant-design/icons';
import { signInWithGooglePopup, signInWithApplePopUp } from "../../utils/firebase.utils"
import { googleLoginRequest, appleLoginRequest } from '../../lib/authActions';
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { Button } from 'reactstrap';

function LoginOptions() {

  const navigate = useNavigate()
  const [loginFailed, setloginFailed] = useState(false)

  const goToRegister = useCallback(() => {
    navigate(AUTHENTICATION_STACK.REGISTER_OPTIONS)
  }, [navigate])

  const handleGoogleSignIn = async () => {

        function onSuccess(userData) {            
            const {access, refresh} = userData.tokens
            window.localStorage.setItem("username", "")
            window.localStorage.setItem("access", JSON.stringify(access))
            window.localStorage.setItem("refresh", JSON.stringify(refresh))

            navigate(STUDIO_STACK.SELECTION_PAGE, {state: {fromAuth: true}})
        }

        function onFail(){
            setloginFailed(true)
        }

        const response = await signInWithGooglePopup();
        const credential =  GoogleAuthProvider.credentialFromResult(response)
        const idToken = credential.idToken
        await googleLoginRequest(idToken, onSuccess, onFail)
  }

  const handleAppleSignIn = async () => {
      function onSuccess(userData) {
        const {access, refresh} = userData.tokens
        window.localStorage.setItem("username", "")
        window.localStorage.setItem("access", JSON.stringify(access))
        window.localStorage.setItem("refresh", JSON.stringify(refresh))

        navigate(STUDIO_STACK.SELECTION_PAGE, {state: {fromAuth: true}})
      }
  
      function onFail(){
          setloginFailed(true)
      }
  
      const response = await signInWithApplePopUp();
      const credentials = OAuthProvider.credentialFromResult(response)
  
      await appleLoginRequest(credentials, onSuccess, onFail)
  }

  const goToEmailLogin = useCallback( () => {
    navigate(AUTHENTICATION_STACK.LOGIN_WITH_EMAIL)
  }, [navigate])

  const goToMobileLogin = useCallback(() => {
    navigate(AUTHENTICATION_STACK.LOGIN_WITH_MOBILE)
  }, [navigate])


  return (
    <div className="app-container-options" >

      <div className='my-4 auth-top-bar'>
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>

      <div className='welcome-text-box'>
        <p className="welcome-text">Welcome back!</p>
        <p className="welcome-msg"> Log in to continue enjoying Clubbie.</p>
      </div>

      {loginFailed && <div>
                            <p className="login-failed-msg">Login failed.</p>
                        </div>}

      <div className='button-container'>
      <Button 
            className='login-option-button'
            onClick={handleGoogleSignIn}>
          <GoogleOutlined/>
          <span className='login-option-text'> Continue with Google </span>
        </Button>

        <Button 
            className='login-option-button'
            onClick={handleAppleSignIn}>
              
            <AppleFilled/>
            <span className='login-option-text'> Continue with Apple </span>        
        </Button>

        <Button 
            className='login-option-button'
            onClick={goToEmailLogin}>

          <MailFilled/>
          <span className='login-option-text'> Continue with Email </span>    
        </Button>

        <Button 
            className='login-option-button'
            onClick={goToMobileLogin}>
            <MobileFilled/>
            <span className='login-option-text'> Continue with Mobile Number </span>   
        </Button>

        <p className='go-register-msg'>
            You don't have an account?
          <button className='go-register-button' onClick={goToRegister}>
            Register.
          </button>
        </p>

      </div>
  </div>
  )
}


export default LoginOptions